import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './Pages/Home';

import About from './Pages/About';
import DigitalMarketing from './/Pages/DigitalMarketing';
import './App.css';
import ContentMarketing from '../src/DigitalMarketing/ContentMarketing';
import SEO from './DigitalMarketing/SEO';
import Footer from './components/Footer';
import DataAnalytic from'../src/Pages/DataAnalytic';
import ServiceDetail from './Pages/ServiceDetails';
import PPC from './DigitalMarketing/PPC';
import Contact from './Pages/Contact';
import EmailMarketing from './DigitalMarketing/EmailMarketing';
import SocialMediaMarketing from './DigitalMarketing/SocialMediaMaketing';
import WebAnalytic from './DigitalMarketing/WebAnalytic';
import ServicesList from './components/ServiceList';
import CloudComputing from './Pages/CloudComputing';
import CyberSecurity from './Pages/CyberSecurity';
import WebDevelopment from './Pages/WebDevelopment';
import MobileApp from './Pages/MobileApp';
import SoftwareIntegration from './Pages/SoftwareIntegration';
import ManagedServices from './Pages/ManagedServices';
import ITSupport from './Pages/ITSupport';
import Managing from './Pages/Managing';



const App = () => {
  return (
    <Router>
      
      <Header/>
 
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<ServicesList />} />
          <Route path="/about" element={<About />} />
                <Route path="/services/:id" element={<ServiceDetail />} />
                <Route path="/" element={<ServicesList />} />  {/* Default route */}
        <Route path="Contact" element={<Contact />} />
      
          <Route path="/Data Services" element={<DataAnalytic />} />
          <Route path="/about" element={<About />} />
        
          <Route path="Digital Marketing" element={<DigitalMarketing />} />
      <Route path="SEO" element={<SEO />} />
      <Route path="Social Media Marketing" element={<SocialMediaMarketing/>} />
      <Route path="Content Marketing" element={<ContentMarketing />} />
      <Route path="ppc" element={<PPC />} />
      <Route path="Email Marketing" element={<EmailMarketing />} />
      <Route path="Web Analytic Reporting" element={<WebAnalytic />} />
      <Route path="cloud-computing" element={<CloudComputing/>} />
      
      <Route path="cybersecurity" element={<CyberSecurity/>} />
      <Route path="data-analytics" element={<DataAnalytic/>} />
    
      <Route path="web-development" element={<WebDevelopment/>} />
      <Route path="mobile-app-development" element={<MobileApp/>} />
      <Route path="Software-Integration" element={<SoftwareIntegration/>} />
      <Route path="Managed-IT-Services" element={<ManagedServices/>} />
      <Route path="IT-Support" element={<ITSupport/>} />
      <Route path="Management" element={<Managing/>} />


         </Routes>
      </main>
      <Footer/>
    </Router>
  );
};

export default App;
