// src/PPCServicePage.js
import React from 'react';
import './PPC.css'; // Import CSS for styling
import ppc from '../assets/ppc.jpg';

const PPCServicePage = () => {
  return (
    <div className="ppc-service-container">
      <div className="content">
        <h1>PPC (Pay-Per-Click) Services</h1>
        <p>
          Our PPC Services are designed to help businesses achieve maximum visibility and drive targeted traffic to their websites.
          With a focus on ROI and performance, we create customized PPC campaigns that align with your business goals.
        </p>
        <h2>What We Offer:</h2>
        <ul>
          <li>Google Ads Management</li>
          <li>Bing Ads Management</li>
          <li>Ad Copywriting and Design</li>
          <li>Keyword Research and Targeting</li>
          <li>Performance Monitoring and Reporting</li>
        </ul>
        <p>
          Collaborate with us to optimize your PPC strategy and achieve measurable results. Our team of experts will work closely with you to ensure your campaigns deliver the best outcomes.
        </p>
        <a href="/Digital Marketing" className="contact-button">Go Back</a> {/* Link */}
      </div>
      <div className="image">
        <img src={ppc} alt="PPC Services" />
      </div>
    </div>
  );
};

export default PPCServicePage;
