// ServiceDetail.js
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import './ServiceDetail.css';

const serviceDetails = {
    1: { title: 'Managed IT Services', content: 'Detailed description of Managed IT Services...' },
    2: { title: 'Cloud Computing', content: 'Detailed description of Cloud Computing...' },
    3: { title: 'Cybersecurity', content: 'Detailed description of Cybersecurity...' },
    // Add other services here
};

function ServiceDetail() {
    const { id } = useParams();
    const service = serviceDetails[id];

    if (!service) {
        return <p>Service not found</p>;
    }

    return (
        <div className="services-container">
            <h1>{service.title}</h1>
            <p>{service.content}</p>
            <Link to="/services" className="back-link">Back to Services</Link>
        </div>
    );
}

export default ServiceDetail;
