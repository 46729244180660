// src/ContentMarketingPage.js
import React from 'react';
import './ContentMarketing.css'; // Import CSS for styling
import content from '../assets/content.jpg';

const ContentMarketingPage = () => {
  return (
    <div className="content-marketing-container">
      <div className="content">
        <h1>Content Marketing Services</h1>
        <p>
          Our Content Marketing Services are tailored to help you attract and engage your target audience through compelling content.
          From blog posts to infographics, we create content that drives results and builds your brand's authority and market the value.
        </p>
        <h2>What We Offer:</h2>
        <ul>
          <li>Content Strategy Development</li>
          <li>Blog Writing and Management</li>
          <li>SEO-Optimized Content Creation</li>
          <li>Social Media Content Planning</li>
          <li>Performance Analytics and Reporting</li>
        </ul>
        <p>
          Partner with us to enhance your content marketing strategy and increase your online visibility. Our team will collaborate with you to deliver high-quality content that resonates with your audience.
        </p>
        <a href="Digital Marketing" className="contact-button">Go back</a> {/* Link */}
      </div>
      <div className="image">
        <img src={content} alt="Content Marketing" />
      </div>
    </div>
  );
};

export default ContentMarketingPage;
