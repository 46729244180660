// src/SEOServicePage.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import '../App.css';
import SEObg from '../assets/SEObg.webp';

const SEO = () => {
  return (
    <div className="seo-service-container">
      <div className="content">
        <h1>Our SEO Services</h1>
        <p>
          Search Engine Optimization (SEO) is essential for enhancing your online visibility and driving organic traffic to your website.
          Our comprehensive SEO services include keyword research, on-page optimization, technical SEO, and link building.
        </p>
        <h2>What We Offer:</h2>
        <ul>
          <li>Keyword Research and Analysis</li>
          <li>On-Page Optimization</li>
          <li>Technical SEO Audits</li>
          <li>Link Building Strategies</li>
          <li>Content Marketing and Strategy</li>
        </ul>
        <p>
          Partner with us to improve your search engine rankings and maximize your online presence. We customize our strategies to align with your business goals.
        </p>
        <Link to="/Digital Marketing" className="contact-button">Go Back</Link> {/* Updated Link */}
      </div>
      <div className="image">
        <img src={SEObg} alt="SEO Services" />
      </div>
    </div>
  );
};

export default SEO;
