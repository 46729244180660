import React from 'react';
import './CloudComputing.css';
import CC from '../assets/CC.jpg';


const CloudComputing = () => {
  return (
    <div className="cloud-container">
      <div className="cloud-content">
        <h1>Cloud Computing Services</h1>
        <p>
          Our cloud computing services empower your business with scalable, flexible, and secure
          solutions. We provide infrastructure, platform, and software services to suit your
          unique business needs. With the latest in cloud technology, you can optimize costs,
          improve efficiency, and gain a competitive edge.
        </p>
        <p>
          Whether you’re looking to migrate to the cloud or enhance your current setup, our team
          is here to support every step of the journey. We specialize in custom cloud solutions,
          ensuring seamless integration with your existing systems.
        </p>
        <a href="services" className="hero-button">Go Back</a>
      </div>
      <div className="cloud-image">
        <img src={CC} alt="Cloud Computing" />
      </div>
    </div>
  );
};

export default CloudComputing;
