import React from 'react';
import './ITSupport.css';
import fisu from '../assets/fisu.jpg';


const ITSupport= () => {
  return (
    <div className="cloud-container">
      <div className="cloud-content">
        <h1> IT Support?</h1>
        <p>
        IT support is a critical aspect of ensuring that a company’s technology infrastructure operates smoothly and efficiently.
         It encompasses a wide range of services that help businesses resolve technical issues, optimize their IT systems,
          and maintain continuous, secure operations.
        </p>
        <p>
        IT support is a critical aspect of ensuring that a company’s technology 
        infrastructure operates smoothly and efficiently. It encompasses a wide range of 
        services that help businesses resolve technical issues, optimize their IT systems, 
        and maintain continuous, secure operations.

</p>

      

       
        <a href="services" className="hero-button">Go Back</a>
      </div>
      <div className="cloud-image">
        <img src={fisu} alt="Cloud Computing" />
      </div>
    </div>
  );
};

export default ITSupport;
