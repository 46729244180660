// src/components/ServiceListing.js
import React from 'react';
import services from '../Pages/Services';

const ServiceList = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Our IT Services </h1>
      <p>
      IT services are integral to modern businesses, providing essential support for daily operations,
       security, efficiency, and scalability. As technology continues to evolve, the role of IT 
       services becomes increasingly vital for companies to remain competitive, secure, and productive. 
       Below are some key reasons why IT services are essential for a company's success:
      </p>
      <div style={styles.serviceGrid}>
        {services.map(service => (
          <div key={service.id} style={styles.card}>
            <img src={service.image} alt={service.title} style={styles.image} />
            <h2 style={styles.cardTitle}>{service.title}</h2>
            <p style={styles.description}>{service.description}</p>
            <a href={service.link} style={styles.link}>Read More</a>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
    
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  serviceGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '20px',
  },
  card: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    textAlign: 'center',
    padding: '10px',
  },
  image: {
    width: '100%',
    height: '150px',
    objectFit: 'cover',
  },
  cardTitle: {
    fontSize: '1.5em',
    margin: '10px 0',
  },
  description: {
    color: '#555',
    marginBottom: '10px',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
};

export default ServiceList;
