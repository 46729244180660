// src/EmailMarketingPage.js
import React from 'react';
import '../App.css'; // Import CSS for styling
import image1 from '../assets/image1.jpg';
import './EmailMarketing.css';
import EMM from '../assets/EMM.jpg';

const EmailMarketing = () => {
  return (
    <div className="email-marketing-container">
      <div className="content">
        <h1>Email Marketing Services</h1>
        <p>
          Our Email Marketing Services are designed to help you engage your audience, nurture leads, and boost conversions.
          With our targeted email campaigns, you can reach your customers directly in their inboxes.
        </p>
        <h2>What We Offer:</h2>
        <ul>
          <li>Custom Email Template Design</li>
          <li>Targeted Email Lists</li>
          <li>A/B Testing for Campaign Optimization</li>
          <li>Performance Analytics and Reporting</li>
          <li>Automation and Drip Campaigns</li>
        </ul>
        <p>
          Join us to enhance your email marketing strategy and improve your ROI. Our team will work closely with you to create effective campaigns.
        </p>
        <a href="Digital Marketing" className="contact-button">Go Back</a> {/* Link */}
      </div>
      <div className="image">
        <img src={EMM} alt="Email Marketing" />
      </div>
    </div>
  );
};

export default EmailMarketing;
