import React from 'react';
import './CyberSecurity.css';
import CBCT from '../assets/CBCT.avif'; // Ensure you have an image in the assets folder
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const CyberSecurity = () => {
  return (
    <div className="cyber-container">
      <div className="cyber-content">
        <h1>Cyber Security Solutions</h1>
        <p>
          Protect your digital assets with our comprehensive cyber security services. We provide
          advanced protection against data breaches, cyber threats, and other vulnerabilities.
          Our team of experts uses the latest technology to safeguard your infrastructure.
        </p>
        <p>
          From threat detection and prevention to compliance and risk management, our solutions
          are designed to meet your unique security needs. Trust us to keep your business secure
          in an increasingly digital world.
        </p>
        <a href="services" className="hero-button">Go Back</a>
      </div>
      <div className="cyber-image">
        <img src={CBCT} alt="Cyber Security" />
      </div>
    </div>
  );
};

export default CyberSecurity;
