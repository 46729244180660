// src/components/Header.js
import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import './Header.css'; // Import CSS for custom styling
import rrr from '../assets/rrr.png';
import { FaWhatsapp, FaEnvelope } from 'react-icons/fa'; // Import icons

const Header = () => {
  return (
    <Navbar bg="light" expand="lg" className="header-navbar">
      <Container>
        <Navbar.Brand href="/" className="logo">
        <img src={rrr} alt="Logo" className="logo-image" /> {/* Logo Image */}
          
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">

            
          <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="services">IT Services</Nav.Link>
            
            <Nav.Link href="Management"> Management</Nav.Link>
            <Nav.Link href="Digital Marketing">Digital Marketing</Nav.Link>
            
            
            
          </Nav>

          <div className="contact-info">
            {/* WhatsApp Contact */}
          
            <a href="https://wa.me/9052216640" className="contact-link">
              <FaWhatsapp className="icon" />
              +91 9052216640
            </a>
            
           
            {/* Email Contact */}
            <a href="https://mail.google.com/mail/u/0/#inbox" className="contact-link">
              <FaEnvelope className="icon" />
              info@raynnbusinessconsultancy.com
            </a>
           
          </div>
         
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
