// src/WebAnalyticsServicePage.js
import React from 'react';
import './WebAnalytic.css'; // Import CSS for styling
import web from '../assets/web.jpg';


const WebAnalytic= () => {
  return (
    <div className="web-analytics-container">
      <div className="content">
        <h1>Web Analytics and Reporting Services</h1>
        <p>
          Our Web Analytics and Reporting Services provide insights into your website's performance,
          user behavior, and overall digital marketing effectiveness. We help you understand your audience
          and make data-driven decisions to enhance your online presence.
        </p>
        <h2>What We Offer:</h2>
        <ul>
          <li>Comprehensive Website Performance Analysis</li>
          <li>User Behavior Tracking and Insights</li>
          <li>Custom Dashboard Creation</li>
          <li>Regular Performance Reporting</li>
          <li>Data-Driven Strategy Recommendations</li>
        </ul>
        <p>
          Partner with us to gain actionable insights that will drive your business growth and improve user experience.
        </p>
        <a href="/Digital Marketing" className="contact-button">Go Back</a> {/* Link */}
       
      </div>
      <div className="image">
        <img src={web} alt="Web Analytics" />
      </div>
    </div>
  );
};

export default WebAnalytic;
