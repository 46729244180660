// src/SocialMediaMarketingPage.js
import React from 'react';
import './SocialMediaMarketing.css'; // Import CSS for styling
import smm from '../assets/smm.avif';

const SocialMediaMarketing = () => {
  return (
    <div className="social-media-marketing-container">
      <div className="content">
        <h1>Social Media Marketing Services</h1>
        <p>
          Our Social Media Marketing Services are designed to enhance your online presence, engage with your audience, and drive brand awareness.
          From content creation to community management, we handle every aspect of your social media strategy.
        </p>
        <h2>What We Offer:</h2>
        <ul>
          <li>Social Media Strategy Development</li>
          <li>Content Creation and Curation</li>
          <li>Community Management and Engagement</li>
          <li>Paid Advertising Campaigns</li>
          <li>Analytics and Reporting</li>
        </ul>
        <p>
          Collaborate with us to take your social media efforts to the next level. Our dedicated team will create impactful campaigns that resonate with your target audience.
        </p>
        <a href="/Digital Marketing" className="contact-button">Go Back</a> {/* Link */}
      </div>
      <div className="image">
        <img src={smm} alt="Social Media Marketing" />
      </div>
    </div>
  );
};

export default SocialMediaMarketing;
