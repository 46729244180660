import React from 'react';
import './ManagedServices.css';
import ms from '../assets/ms.jpg';


const ManagedServices = () => {
  return (
    <div className="cloud-container">
      <div className="cloud-content">
        <h1> What Are Managed IT Services?</h1>
        <p>
        Managed IT services involve a proactive approach to managing IT infrastructure and 
        support, where a third-party service provider assumes the responsibility of maintaining,
         monitoring, and upgrading IT systems. These services can cover various aspects of technology, 
         such as network management,
         data protection, cloud computing, and end-user support.
        </p>
        <p>
        The managed services provider (MSP) acts as an outsourced IT department, offering ongoing support and maintenance, 
        often for a fixed monthly fee. 
        This is in contrast to break/fix IT support, where businesses pay for service only when something goes wrong.
<p>

</p>

        </p>

        <p>
        

        </p>

        <p>
        
        </p>
        <a href="services" className="hero-button">Go Back</a>
      </div>
      <div className="cloud-image">
        <img src={ms} alt="Cloud Computing" />
      </div>
    </div>
  );
};

export default ManagedServices ;
