// src/DataAnalyticsServicePage.js
import React from 'react';
import './DataAnalytic.css'; // Import CSS for styling


const DataAnalytic = () => {
  return (
    <div className="data-analytics-container">
      <div className="content">
        <h1>Data Analytics Services</h1>
        <p>
          Our Data Analytics Services empower companies to harness the power of their data.
          We offer comprehensive analytics solutions that turn raw data into actionable insights, 
          driving informed decision-making and strategic growth.
        </p>

        <h2>Key Components of Our Services:</h2>
        <ul>
          <li><strong>Data Collection and Preparation:</strong> Gathering and cleaning data from various sources.</li>
          <li><strong>Data Analysis:</strong> Employing statistical methods to analyze data trends.</li>
          <li><strong>Data Visualization:</strong> Creating visual representations to simplify complex data.</li>
          <li><strong>Predictive Analytics:</strong> Forecasting future trends based on historical data.</li>
          <li><strong>Business Intelligence (BI):</strong> Integrating data for a cohesive view of performance.</li>
          <li><strong>Custom Analytics Solutions:</strong> Tailored analytics to meet specific business needs.</li>
          <li><strong>Ongoing Monitoring and Optimization:</strong> Continuously tracking data for performance improvements.</li>
        </ul>

        <h2>Importance of Data Analytics for Companies:</h2>
        <ol>
          <li><strong>Informed Decision-Making:</strong> Make strategic choices backed by data insights.</li>
          <li><strong>Improved Customer Insights:</strong> Tailor products and marketing efforts to meet customer needs.</li>
          <li><strong>Operational Efficiency:</strong> Streamline processes to reduce costs and enhance productivity.</li>
          <li><strong>Competitive Advantage:</strong> Stay ahead of market trends and competitor strategies.</li>
          <li><strong>Risk Management:</strong> Identify potential risks and develop mitigation strategies.</li>
          <li><strong>Enhanced Marketing Strategies:</strong> Measure campaign effectiveness and improve ROI.</li>
          <li><strong>Revenue Growth:</strong> Focus on high-performing areas to drive profitability.</li>
          <li><strong>Innovation and Product Development:</strong> Identify market gaps and create innovative solutions.</li>
        </ol>

        <a href="services" className="hero-button">Go Back</a>
      </div>
      <div className="right-image">
        
      </div>
    </div>
  );
};

export default DataAnalytic;
