// src/components/FullHeroSection.js
import React from 'react';
import './Herosection.css'; // Import CSS for stylin

const HeroSection = () => {
  return (
    <div className="full-hero-section">
      <div className="hero-content">
        <h1>Transform your Business</h1>
        <p>
        We are a leading IT consultancy firm dedicated to empowering 
        businesses through solutions. We specialize in various services including web development, mobile app development, and digital marketing.
        
        </p>
        <a href="services" className="hero-button">Explore Services</a>
      </div>
    </div>
  );
};

export default HeroSection;
