// src/services.js
import CC from '../assets/CC.jpg';
import CBCT from'../assets/CBCT.avif';
import DA from'../assets/DA.jpg';
import MD from '../assets/DA.jpg';
import WD from '../assets/WD.jpg';
import ms from '../assets/ms.jpg';
import './services.css';
import { Button } from 'react-bootstrap';
import Software from '../assets/Software.png';
import fisu from '../assets/fisu.jpg';

const services = [
    {
      id: 1,
      title: "Cloud Computing",
      description: "Scalable cloud solutions to enhance business operations.",
      image: CC,
      link: "cloud-computing"
    },
    {
      id: 2,
      title: "Cybersecurity",
      description: "Protecting your business from cyber threats.",
      image: CBCT,
      link: "/cybersecurity"
    },
    {
      id: 3,
      title: "Data Analytics",
      description: "Turn data into actionable insights.",
      image: DA,
      link: "/data-analytics"
    },
    {
      id: 4,
      title: "Web Development",
      description: "Building responsive and engaging websites.",
      image: WD,
      link: "/web-development"
    },
    {
      id: 5,
      title: "Mobile App Development",
      description: "Creating user-friendly mobile applications.",
      image: MD,
      link: "/mobile-app-development"
    },

    {
        id: 6,
        title: "Managed IT Services",
        description: "IT services are integral to modern businesses, providing essential support for daily operations.",
        image: ms,
        link: "/Managed-IT-Services"
      },

      {
        id: 7,
        title: "Software Integration",
        description: "Integrate with with ERP planning and with new Technology.",
        image: Software,
        link: "/Software-Integration"
      },

      {
        id: 8,
        title: "IT Support",
        description: "We do provide IT Post implemetation Services involves both onshore and offshore.",
        image: fisu,
        link: "IT-Support"
      },
    // Add more services as needed
  ];
  
  export default services;
  