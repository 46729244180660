// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

// Your Firebase config from Firebase Console
const firebaseConfig = {
    apiKey: "AIzaSyBEAnCD7R3cg9crv-9DLP2Xy0IpxQZLGi4",
    authDomain: "firing-a18ef.firebaseapp.com",
    databaseURL: "https://firing-a18ef-default-rtdb.firebaseio.com",
    projectId: "firing-a18ef",
    storageBucket: "firing-a18ef.firebasestorage.app",
    messagingSenderId: "883194536326",
    appId: "1:883194536326:web:42885a2e2a5b6d4c124c07",
    measurementId: "G-KKQ3M97KJW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection, addDoc };
